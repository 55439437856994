import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Box } from "../components/structure/Box";
import { Typography } from "../components/structure/Typography";
import Footer from "../components/Footer";

// @ts-ignore
import palmTreeLeft from "../assets/images/views/main/palm_tree_left.png";
// @ts-ignore
import palmTreeRight from "../assets/images/views/main/palm_tree_right.png";
// @ts-ignore
import logo from "../assets/images/brand/logo.png";

const MainView = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1424px)' });

  const [rewards, setRewards] = useState({})

  useEffect(()=> {
    fetch('https://api.big-fish.cloud/web3/treasure-chest/rewards')
    .then((response) => response.json())
    .then((data) => setRewards(data.data.rewards));
  }, []);

  return (
    <Box
      width="100%"
      height="100%"
      style={{
        background: "linear-gradient(180deg, #35B3DF 0%, #82D7F1 100%)",
      }}
    >
      <Box
        display={isTabletOrMobile ? "none" : "flex"}
        position="fixed"
        left="0px"
        bottom="0px"
      >
        <img src={palmTreeLeft} alt="Left Palm Tree" />
      </Box>
      <Box
        display={isTabletOrMobile ? "none" : "flex"}
        position="fixed"
        right="0px"
        bottom="0px"
      >
        <img src={palmTreeRight} alt="Right Palm Tree" />
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        maxWidth="1000px"
        margin="auto"
        paddingY="50px"
        gridGap="25px"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <img width="70%" src={logo} alt="Logo" />
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            fontFamily="Freude"
            fontSize={isTabletOrMobile ? "50px" : "60px"}
            color="#FCF1E3"
          >
            Treasure Chests
          </Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          gridGap="50px"
          width="100%"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="50%"
          >
            <p>Treasure Chests can be opened in the Castaways game with a old key, an item found from fishing in the ocean. When Opened, the Treasure Chest NFT is burned and the player is given either in game items or an NFT reward.</p>
          </Box>

        {rewards["Raft"]
        ? <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            width="50%"
          >
            <p>Raft: {rewards["Raft"]["remaining"]}/{rewards["Raft"]["total"]} remaining</p>
            <p>Islands: {rewards["Island"]["remaining"]}/{rewards["Island"]["total"]} remaining</p>
            <p>Genesis Character: {rewards["Genesis Character"]["remaining"]}/{rewards["Genesis Character"]["total"]} remaining</p>
            <p>Extra Treasure Chests: {rewards["Treasure Chest"]["remaining"]}/{rewards["Treasure Chest"]["total"]} remaining</p>
          </Box>
        : ''
        }
        </Box>
        
        <Box marginTop="25px">
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

export default MainView;
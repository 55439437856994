import React, { useState } from "react";
import PropTypes from "prop-types";

const Link = ({
  onClick, href, target, color, colorHover, children,
}) => {
  const [hover, setHover] = useState(false);

  const onMouseEnter = () => setHover(true);

  const onMouseLeave = () => setHover(false);

  const handleClick = (e) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  }

  return (
    <a
      onClick={handleClick}
      href={href ? href : "#"}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      target={target}
      style={{
        color: hover ? colorHover : color,
        textDecoration: hover ? "underline" : "none",
        cursor: "pointer",
      }}
    >
      {children}
    </a>
  );
};

Link.defaultProps = {
  onClick: null,
  href: null,
  target: null,
  color: "white",
  colorHover: "lightgray",
};

Link.propTypes = {
  onClick: PropTypes.func,
  href: PropTypes.string,
  target: PropTypes.string,
  color: PropTypes.string,
  colorHover: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Link;

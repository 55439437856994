import React from "react";
import { Box } from "./structure/Box";
import Link from "./structure/Link";

// @ts-ignore
import discordFlat from "../assets/images/views/main/footer/discord_flat.png";
// @ts-ignore
import twitterFlat from "../assets/images/views/main/footer/twitter_flat.png";
// @ts-ignore
import openSeaFlat from "../assets/images/views/main/footer/opensea_flat.png";

const Footer = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    gridGap="50px"
  >
    <Box>
      <Link
        href="https://discord.gg/castaways"
        target="_blank"
      >
        <img src={discordFlat} alt="Castaways Discord" />
      </Link>
    </Box>
    <Box>
      <Link
        href="https://twitter.com/castaways_gg"
        target="_blank"
      >
        <img src={twitterFlat} alt="Castaways Twitter" />
      </Link>
    </Box>
    <Box>
      <Link
        href="https://opensea.io/collection/castaways-treasure-chests"
        target="_blank"
      >
        <img src={openSeaFlat} alt="OpenSea" />
      </Link>
    </Box>
  </Box>
);

export default Footer;